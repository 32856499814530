import store from "../index"
import { Module,VuexModule,Mutation,getModule } from "vuex-module-decorators"
import { NoticeType,MenusType } from "../../Type/index"

export interface HomeType<T>{
    BannerList:Array<T>;
    MenusList:Array<MenusType>
    NoticeList:Array<NoticeType>;
    NoticeNum:number;

    SetNoticeNum(state:number):void;
    SetMenusList(data:Array<MenusType>):void;
    SetNoticeList( data:Array<NoticeType> ):void;
    SetBannerList( data:Array<T> ):void;
}

@Module({
    name:"Home",
    store,
    dynamic:true
})
export default class Home extends VuexModule implements HomeType<string>{
    BannerList:Array<string> = [  ];//require("$img/Banner.png")
    NoticeList:Array<NoticeType> = [];
    MenusList:Array<MenusType> = []
    NoticeNum = 0;

    /**
     * 通知数量
    */
    get GetNoticeNum(){
        return this.NoticeNum
    }

    @Mutation
    SetNoticeNum( data:number ){
        this.NoticeNum = data
    }

    /**
     * 九宫格功能开放
    */
    get GetMenusList(){
        return this.MenusList
    }

    @Mutation
    SetMenusList(data:Array<MenusType>){
        this.MenusList = data
    }

    /**
     * 通知列表
    */
    get GetNoticeList(){
        return this.NoticeList
    }

    @Mutation
    SetNoticeList( data:Array<NoticeType> ){
        this.NoticeList = data
    }
    
    /**
     * banner 列表
    */
    get GetBannerList(){
        return this.BannerList
    }
    @Mutation
    SetBannerList( data:Array<string> ) {
        this.BannerList = data 
    }
}

export const HomeStore = getModule( Home )
